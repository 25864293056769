<template>
    <div class="report-sidebar-container hide" :class="{ closedRightSidebar: isMobile && !isRightSidebarOpen }">
        <div v-if="isRightSidebarOpen" class="report-sidebar">
            <div v-if="!isMobile" class="sidebar-section no-left-border search-section">
                <SearchBar
                    :showNoResultsMsg="showNoResultsMsg"
                    @searchInputChange="
                        (value) => {
                            $emit('searchInputChange', value)
                        }
                    "
                />
            </div>

            <!-- DISPLAY SETTINGS -->
            <div class="sidebar-section" :class="{ 'left-border-transparent': !displaySettingsIsOpen }">
                <div class="section-title" @click="toggleShowDisplaySettings">
                    <h3>{{ $t('nvgt.report.display_settings.title').toUpperCase() }}</h3>
                    <img v-if="!displaySettingsIsOpen" src="img/arrow_down.svg" :aria-label="$t('nvgt.report.display_settings.show_btn')" />
                    <img v-else="displaySettingsIsOpen" src="img/arrow_up.svg" :aria-label="$t('nvgt.report.display_settings.hide_btn')" />
                </div>
                <div v-if="displaySettingsIsOpen" class="section-content">
                    <div v-for="(setting, index) in displaySettings" :key="index" class="option">
                        <input
                            type="checkbox"
                            :value="setting.value"
                            @change="
                                () => {
                                    $emit('checkboxChange', { type: setting.type, value: !setting.value })
                                }
                            "
                            class="switch-input"
                            :class="{ 'switch-input-checked': setting.value }"
                        />
                        <p class="setting">{{ setting.label }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchBar from './SearchBar.vue'

export default {
    name: 'ReportSidebar',
    components: { SearchBar },
    data() {
        return {
            displaySettingsIsOpen: true,
            windowWidth: window.innerWidth
        }
    },
    props: {
        displaySettings: {
            type: Array,
            default: undefined
        },
        showNoResultsMsg: {
            type: Boolean,
            default: false
        },
        isRightSidebarOpen: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isMobile(val) {
            this.isRightSidebarOpen = !val
        }
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 767
        }
    },
    methods: {
        toggleShowDisplaySettings() {
            this.displaySettingsIsOpen = !this.displaySettingsIsOpen
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss" scoped>
.report-sidebar-container {
    position: absolute;
    top: 0;
    right: 0;
    // min-width: 308px;
    // max-width: 386px;
    // width: 30%;
    width: 100%;
    padding-left: 16px;
    // padding-right: 16px;
    height: 100%;

    @media (min-width: 768px) {
        min-width: 308px;
        max-width: 386px;
        width: 30%;
        padding-right: 16px;
    }

    p {
        padding: 0px !important;
    }

    .report-sidebar {
        position: relative;
        // width: 276px;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        padding: 16px 8px;
        // border-radius: 4px;
        // box-shadow: 0 0 10px 0 #e2e2e2;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (min-width: 768px) {
            border-radius: 4px;
            box-shadow: 0 0 10px 0 #e2e2e2;
        }

        .left-border-transparent {
            border-left: 5px solid transparent !important;
        }

        .no-left-border {
            border-left: none !important;
        }

        .sidebar-section {
            position: relative;
            display: flex;
            flex-direction: column;
            border-left: 5px solid $color-secondary-100;
            border-radius: 4px;
            .section-title {
                // max-width: 246px;
                position: relative;
                max-width: 97%;
                width: 246px;
                justify-content: space-between;
                color: $color-secondary-800;
                background-color: $color-secondary-100;
                width: 100%;
                min-height: 40px;
                border-radius: 2px;
                align-self: flex-end;
                padding: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;

                h3 {
                    font-size: 16px;
                }

                img {
                    max-width: 16px;
                    width: 16px;
                }
            }

            .section-content {
                padding: 16px 8px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .option {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 8px;

                    .switch-input {
                        position: relative !important;
                        top: 0px !important;
                        left: 0px !important;
                        width: 24.5px;
                        max-width: 24.5px;
                        height: 14px;
                        max-height: 14px;
                        background-color: $color-neutral-600;
                        border-color: $color-neutral-600;
                    }

                    .switch-input:after {
                        width: 9.8px;
                        height: 9.8px;
                        left: 1px;
                    }

                    .switch-input-checked {
                        border-color: $color-primary-500;
                        background-color: $color-primary-500 !important;
                    }

                    .switch-input-checked:after {
                        left: 11px;
                    }
                    .setting {
                        position: relative;
                        font-size: 16px;
                        font-family: $text;
                        line-height: 1.3em;
                    }
                }
            }
        }

        .search-section {
            padding-bottom: 16px;
        }
    }
}
.closedRightSidebar {
    width: 12px !important;
    min-width: 12px !important;
    background-color: $color-white;

    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
}
</style>
