<template>
    <div id="content" class="supervise-issue" v-if="!reportView && canLoadList">
        <ViewConfigForm
            v-if="displayViewConfigForm"
            :selectedView="selectedView"
            :isEdit="configViewIsEdit"
            @view-created-or-edited="handleDisplayView"
            @on-view-deleted="setDefaultViewAsSelectedView"
            @on-cancel-button="closeViewConfigForm"
        />

        <div class="supervise-top-bar-actions">
            <div class="sidebar-filters-top-buttons">
                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                <!-- VIEWS DROPDOWN MENU and Edit View button -->
                <template v-if="showViews && viewsList.length > 0">
                    <ViewsDropdownMenu
                        v-if="!isSmallScreen"
                        :views="viewsList"
                        :selectedView="selectedView"
                        @on-view-selected="handleDisplayView"
                        @on-create-view="handleCreateView"
                        :newFiltersAppliedToAView="newFiltersAppliedToAView"
                    />
                    <Button v-if="showEditViewButton && !isSmallScreen" bType="editIcon" customClass="edit-view-button" @on-button-click="handleEditView" />
                    <Button
                        v-if="newFiltersAppliedToAView && !isSmallScreen"
                        bType="createIcon"
                        customClass="edit-view-button"
                        @on-button-click="handleCreateView"
                    />
                </template>
            </div>
            <div class="supervise-table-actions-buttons">
                <Button v-if="selected.length > 0 && !isMobile" bType="file" :bCallback="goToReport" :bLabel="pluralize()" />

                <Button bType="more" :bCallback="showMoreOptions" />

                <div class="more-options" v-if="moreOptions">
                    <Button
                        bType="file"
                        :bCallback="downloadCsv"
                        :bLabel="$tc('supervise.checklists.table_headers.download_csv', selected.length == 1 ? 1 : 2)"
                    />
                    <Button
                        v-if="isDlRepor() && selected.length > 0"
                        bType="file"
                        :bCallback="downloadExcel"
                        :bLabel="$tc('supervise.checklists.table_headers.download_excel', selected.length == 1 ? 1 : 2)"
                    />
                    <Button v-if="showDownloadPdf" bType="print" :bCallback="downloadPDF" bLabel="nvgt.report.download_pdf" />
                    <Button bType="delete" :bCallback="deleteSelectedItem" v-if="selected.length > 0" />
                </div>
            </div>
        </div>

        <div class="supervise-container" :class="{ 'with-trial': trialEndDate }">
            <!-- SIDEBAR FILTERS -->
            <div class="sidebar-filters" :class="{ 'empty-results': items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                <SuperviseIncidentsFiltersBox
                    :selectedView="selectedView"
                    :key="filterKey"
                    :applyFilter="applyFilter"
                    :pagination="pagination"
                    ref="filterIssues"
                    :sortBy="sortBy"
                    :sortType="sortType"
                    :changePagination="changePagination"
                    @reset="showButtonReset"
                    @resetPageDatatable="top(true)"
                    @resetPagination="resetPagination"
                    @on-reset-filters="setDefaultViewAsSelectedView"
                    @filtersApplied="closeSidebarOnMobile"
                />
            </div>
            <!-- <code>
            {{ this.selected }}
           </code> -->
            <!-- TABLE -->
            <div class="data-table" :class="{ 'empty-results': items.length == 0 }">
                <v-data-table
                    :update:sort-by="sortTable()"
                    v-if="items"
                    v-model="selected"
                    :headers="headers"
                    fixed-header
                    :items="items"
                    sort-by="created_date"
                    :sort-desc="true"
                    :single-select="singleSelect"
                    hide-default-footer
                    show-select
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    @page-count="pageCount = $event"
                    :options.sync="pagination"
                >
                    <template #item.location_name="item">
                        <div class="location-name" @click="goToReport(item.item)">
                            <span>{{ item.item.location_name }}</span>
                        </div>
                    </template>
                    <template #item.issue_id="item">
                        <div class="id" @click="goToReport(item.item)">
                            <span class="id">#{{ item.item.issue_id }}</span>
                        </div>
                    </template>
                    <template #item.title="item">
                        <div class="title" @click="goToReport(item.item)">
                            <span>{{ item.item.customTitle ? item.item.customTitle : item.item.title }}</span>
                            <span v-if="!templateIsActive(item.item.template_id)" class="inactive-card"> {{ $t('template.inactive') }}</span>
                        </div>
                    </template>
                    <template #item.status="item">
                        <States :currentState="item.item.status" :issueID="item.item.id"></States>
                    </template>
                    <template #item.created_date="item">
                        <div class="date" @click="goToReport(item.item)">
                            <span class="date-hour" v-html="spanishDateFormat(item.item.created_date)"></span>
                            <span class="hour">{{ getHour(item.item.created_date) }}</span>
                        </div>
                    </template>
                    <template #item.complete_date="item">
                        <div class="date" @click="goToReport(item.item)" v-if="item.item.complete_date">
                            <span class="date-hour" v-html="spanishDateFormat(item.item.complete_date)"></span>
                            <span class="hour">{{ getHour(item.item.complete_date) }}</span>
                        </div>
                        <div class="no-date" v-else @click="goToReport(item.item)">-</div>
                    </template>
                    <!-- <template #item.employee_ref="item">
                        <div class="created-by" @click="goToReport(item.item)" v-if="getEmployee(item.item.employee_ref.id)">
                            <span
                                class="employee-img"
                                :style="{
                                    backgroundImage: 'url(' + getEmployee(item.item.employee_ref.id).avatar + ')'
                                }"
                            ></span>
                            <span class="employee-name">{{ getEmployee(item.item.employee_ref.id).name + ' ' + getEmployee(item.item.employee_ref.id).surname }}</span>
                        </div>
                        <div class="no-employee" v-else>-</div>
                    </template> -->
                    <template #item.employee_ref="item">
                        <TableCreatedBy
                            @goReport="goToReport"
                            v-if="getEmployee(item.item.employee_ref.id)"
                            :item="item"
                            :avatar="getEmployee(item.item.employee_ref.id).avatar"
                            :name="getEmployee(item.item.employee_ref.id).name"
                            :surname="getEmployee(item.item.employee_ref.id).surname"
                        />
                    </template>
                    <template #item.resume="item">
                        <div class="resume" @click="goToReport(item.item)">
                            <div class="summary">
                                <div v-if="!summaryIs0(item.item.resume.it)" class="summary-uncompleted-task" v-tooltip="$t('supervise.checklists.summary_3')">
                                    {{ item.item.resume.it }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_3')"
                                    src="~@/../public/img/task_uncompleted_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.a)" class="summary-alerts" v-tooltip="$t('supervise.checklists.summary_2')">
                                    {{ item.item.resume.a }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_2')"
                                    src="~@/../public/img/task_warning_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.is)" class="summary-incidents" v-tooltip="$t('supervise.checklists.summary_1')">
                                    {{ item.item.resume.is }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_1')"
                                    src="~@/../public/img/task_issue_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.n)" class="summary-note" v-tooltip="$t('supervise.checklists.summary_4')">
                                    {{ item.item.resume.n }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_4')"
                                    src="~@/../public/img/task_note_inactive.svg"
                                />
                            </div>
                            <div class="generated-from" v-if="item.item.references !== false">
                                {{ generatedFrom(item.item.references.type) }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <div class="andy-datatable-empty" v-if="itemsLoaded">
                            <EmptyTable
                                buttonClass="btn-action"
                                @buttonAction="resetFilter"
                                buttonType="clearFilters"
                                :buttonText="showReset ? $t('assets.filter.delete') : undefined"
                                :title="$t('empty_table.users_title_supervise')"
                                type="supervise"
                                :description="$t('empty_table.issues_empty_filter')"
                            ></EmptyTable>
                        </div>
                    </template>
                </v-data-table>
                <div id="pagination" v-if="!(numItems == 0)">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ numItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" @input="changePage" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select :items="rowsPerPage" @change="changedItemPerPage = true" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ReportIssues :reports="aReportsID" @back="closeReport()" v-else-if="selected.length > 0"></ReportIssues>
</template>

<script>
import States from '@/components/domain/issues/states.vue'
import i18n from '@/i18n'
import SuperviseIncidentsFiltersBox from '@/components/domain/issues/supervise/SuperviseIncidentsFiltersBox'
import ReportIssues from '@/components/domain/issues/supervise/report'
import EmptyTable from '@/components/ui/EmptyTable'
import TableCreatedBy from '@/components/ui/TableCreatedBy.vue'
import ViewsDropdownMenu from '@/components/domain/filtersViews/ViewsDropdownMenu.vue'
import ViewConfigForm from '@/components/domain/filtersViews/ViewConfigForm.vue'
import { TYPE } from '@/constants'

export default {
    components: {
        SuperviseIncidentsFiltersBox,
        ReportIssues,
        EmptyTable,
        States,
        TableCreatedBy,
        ViewsDropdownMenu,
        ViewConfigForm
    },
    name: 'SuperviseIssue',
    data() {
        return {
            type: TYPE.ISSUE,
            applyFilter: false,
            showReset: false,
            noChanges: false,
            // VIEW VARIABLES
            reportView: false,
            aReportsID: [],

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('supervise.issues.table_headers.date'),
                    value: 'created_date',
                    align: 'start',
                    sortable: true,
                    sort: this.sortColumnDate,
                    width: '16%',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.issues.table_headers.state'),
                    value: 'status',
                    align: 'start',
                    width: '16%',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.issues.table_headers.id'),
                    align: 'start',
                    sortable: true,
                    value: 'issue_id',
                    width: '90px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.issues.template_issues'),
                    value: 'title',
                    width: '20%',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.localization'),
                    value: 'location_name',
                    align: 'start',
                    width: '20%',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.issues.table_headers.created_by'),
                    value: 'employee_ref',
                    align: 'start',
                    sortable: true,
                    sort: this.sortColumnScore,
                    width: '20%',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.issues.table_headers.date_end'),
                    value: 'complete_date',
                    align: 'start',
                    sortable: true,
                    sort: this.sortColumnDate,
                    width: '16%',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.checklists.table_headers.summary'),
                    value: 'resume',
                    align: 'center',
                    sortable: true,
                    width: '180px',
                    class: 'header-table'
                }
            ],
            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: false,
            changePagination: false,
            changedItemPerPage: false,
            filtersSidebarIsHidden: false,

            // Filters VIEWS
            selectedView: null,
            displayViewConfigForm: false,
            configViewIsEdit: false,
            moreOptions: false,
            tool: 'issues',

            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            const numItems = this.$store.getters['issues/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },
        numItems() {
            return this.$store.getters['issues/getNumItems']
        },
        items() {
            var result = this.$store.getters['issues/getList'](1)
            return Object.values(result)
        },
        itemsLoaded() {
            return this.$store.getters['issues/getItemsLoaded']
        },
        canLoadList() {
            return this.$store.getters['issues/getCanLoadList']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },

        // Filters VIEWS
        viewsList() {
            return this.$store.getters['filtersViews/getSuperviseIncidentsViewsList']
        },

        defaultView() {
            return this.$store.getters['filtersViews/getSuperviseIncidentsDefaultView']
        },

        selectedViewFilters() {
            if (this.selectedView) {
                return this.selectedView.data
            }
        },

        // (feature flag)
        showViews() {
            return this.$store.getters['login/getFilterViewsAvailable']
        },

        showEditViewButton() {
            const userRolLevel = this.$store.getters['getLevelUser']

            if (!this.selectedView) return false

            const viewType = this.selectedView.type

            // if is Standard View (type 1) never show the edit button
            if (viewType === 1) {
                return false
            }

            // if is Custom "Only for me" view (type 3) always show the edit button
            if (viewType === 3) {
                return true
            }

            // if is Custom "For the entire brand" (type 2) show edit button only to high level rol users:
            if (viewType === 2) {
                if (userRolLevel >= 7) {
                    return true
                } else return false
            }
        },

        newFiltersAppliedToAView() {
            const activeFilters = JSON.stringify(this.$store.getters['issues/getActiveFilters'])

            if (this.selectedView) {
                const selectedViewFilters = JSON.stringify(this.selectedView.data)

                return activeFilters !== selectedViewFilters
            }
        },
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        rowsAreSelected() {
            return this.selected.length > 0 ? true : false
        },
        loggedUser() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            if (this.loggedUser) {
                return this.$store.getters['employee/getEmployee'](this.loggedUser)
            }
            return false
        },
        roles() {
            return this.$store.getters['employee/getAllRoles']
        },
        showDownloadPdf() {
            const reportPdf = parseInt(this.$store.getters['account/getReportPdf'])
            if (reportPdf == 1) {
                return true
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        isSmallScreen() {
            // includes mobile and tablets
            return this.windowWidth <= 900
        }
    },
    methods: {
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        deleteSelectedItem() {
            const self = this
            for (let item of this.selected) {
                if (item.config && item.config.nRoles) {
                    const canDelete = item.config.nRoles.some((role) => !role.permissions.includes('w')) || item.config.nRoles.roleId
                    if (!canDelete) {
                        this.$snackbar.open({
                            message: self.$t('snackbar.issues_remove_error'),
                            color: 'error',
                            position: 'top',
                            timeout: 3000
                        })
                        return
                    }
                }
            }
            this.$popup.deleteItem({
                type: 'deleteItem',
                tool: this.tool,
                items: this.selected.filter((item) => {
                    if (item.config && item.config.nRoles) {
                        return item.config.nRoles.some((role) => !role.permissions.includes('w')) || item.config.nRoles.roleId
                    } else {
                        return true
                    }
                }),
                callSave: function () {
                    self.moreOptions = false
                    const checklistsIds = [self.selected.map((check) => check.id)]
                    const userRole = localStorage.user.rol
                    if (self.selected.length > 0 && !self.selected.some((item) => item.config && item.config.nRoles)) {
                        self.$store
                            .dispatch('checklist/delete', { checklist: checklistsIds, status: -3, employee_ref: self.user_id, cType: 3 })
                            .then((response) => {
                                if (response.status) {
                                    self.$popup.close()
                                    self.$overlay.loading()
                                    const checklists_updated = response.checklist_updated
                                    self.$store.commit('issues/updateList', { deletedItems: checklists_updated })
                                    self.$overlay.hide()
                                }
                            })
                    } else if (self.selected.length > 0 && self.selected.every((item) => item.config && item.config.nRoles)) {
                        let isValid = true
                        self.selected.forEach((item) => {
                            const nRoles = item.config.nRoles
                            if (!nRoles.some((role) => role.roleId === userRole && role.permissions.includes('w'))) {
                                isValid = false
                                return
                            }
                        })

                        if (isValid) {
                            self.$store
                                .dispatch('checklist/delete', { checklist: checklistsIds, status: -1, employee_ref: self.user_id, cType: 3 })
                                .then((response) => {
                                    if (response.status) {
                                        self.$popup.close()
                                        self.$overlay.loading()
                                        const checklists_updated = response.checklist_updated
                                        self.$store.commit('issues/updateList', { deletedItems: checklists_updated })
                                        self.$overlay.hide()
                                    }
                                })
                        }
                    }
                },
                callCancel: function () {
                    self.moreOptions = false
                    self.$popup.close()
                }
            })
        },
        sortTable() {
            this.sortBy = this.pagination.sortBy && this.pagination.sortBy.length > 0 ? this.pagination.sortBy : false
            this.sortType =
                this.pagination.sortBy && this.pagination.sortBy.length > 0
                    ? this.pagination.sortDesc.length > 0 && this.pagination.sortDesc[0]
                        ? 'desc'
                        : 'asc'
                    : false
        },

        resetPagination() {
            this.selected = []
            this.changePagination = false
            this.pagination.page = 1
            this.top(true)
        },
        templateIsActive(templateID) {
            var template = this.$store.getters['issues/getFilterTemplate'](templateID)

            if (template && template.status == '-2') {
                return false
            }
            return true
        },

        resetFilter() {
            this.$refs.filterIssues.resetFilters()
            this.showReset = false
        },
        showButtonReset(reset) {
            this.showReset = reset
        },
        top(cond) {
            // if (cond && !this.$route.params.noChangePageDatatable) {
            //     this.pagination.page = 1
            // }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        generatedFrom(type) {
            switch (type) {
                case '1':
                    return i18n.t('supervise.issues.type_1')
                    break
                case '2':
                    return i18n.t('supervise.issues.type_2')
                    break
                case '3':
                    return i18n.t('supervise.issues.type_3')
                    break
                case '4':
                    return i18n.t('supervise.issues.type_4')
            }
        },
        summaryIs0(summary) {
            if (summary == 0) {
                return true
            }
            return false
        },
        pluralize() {
            if (this.selected.length > 1) {
                return 'supervise.checklists.table_headers.show_informs'
            } else {
                return 'supervise.checklists.table_headers.show_inform'
            }
        },
        goToReport(item) {
            const amplitudeProps = { type: 'Issue' }
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Use of reports', amplitudeProps)

            this.$overlay.show()
            if (item) {
                this.selected.push(item)
            }
            var reports = [...this.selected]

            this.aReportsID = []
            for (var index in reports) {
                reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
            }

            this.reportView = true
        },
        isPosibleToDownload() {
            const maxItems = 1000
            if (this.numItems > maxItems) {
                this.$popup.alertNewDesing({
                    title: this.$t('supervise.checklists.error_download_excel'),
                    message: this.$t('supervise.checklists.error_download_excel_rows', { maxItems: maxItems })
                })
                return false
            }

            return true
        },
        isDlRepor() {
            const config = this.$store.getters['login/getConfig']
            return config && config.dlReport === 'true'
        },
        downloadCsv() {
            if (this.items.length == 0) {
                this.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        this.moreOptions = false
                        this.$popup.close()
                    }
                })
                return
            }
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            this.$overlay.show()
            const filters = this.$store.getters['issues/getActiveFilters']

            if (this.selected.length > 0) {
                //Enviar ids
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('issues/downloadReportListCsv', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    //Enviar filtros
                    const location = filters.location ? Object.values(filters.location).map(({ id }) => id) : ''
                    const template = filters.template ? Object.values(filters.template).map(({ id }) => id) : ''
                    const status = filters.status ? Object.values(filters.status).map(({ id }) => id) : ''
                    const category = filters.category ? Object.values(filters.category).map(({ id }) => id) : ''

                    this.$store
                        .dispatch('issues/downloadReportListCsv', {
                            location: location,
                            template: template,
                            status: status,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date,
                            complete_date: filters.complete_date !== 'custom' ? filters.complete_date : filters.complete_date_start,
                            complete_date_end: filters.complete_date_end,
                            pagenum: this.pagination.page,
                            category: category,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            this.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },
        downloadExcel() {
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            this.$overlay.show()
            const filters = this.$store.getters['issues/getActiveFilters']

            if (this.selected.length > 0) {
                //Enviar ids
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('issues/downloadReportListExcel', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    //Enviar filtros
                    const location = filters.location ? Object.values(filters.location).map(({ id }) => id) : ''
                    const template = filters.template ? Object.values(filters.template).map(({ id }) => id) : ''
                    const status = filters.status ? Object.values(filters.status).map(({ id }) => id) : ''
                    const category = filters.category ? Object.values(filters.category).map(({ id }) => id) : ''

                    this.$store
                        .dispatch('issues/downloadReportListExcel', {
                            location: location,
                            template: template,
                            status: status,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date,
                            complete_date: filters.complete_date !== 'custom' ? filters.complete_date : filters.complete_date_start,
                            complete_date_end: filters.complete_date_end,
                            pagenum: this.pagination.page,
                            category: category,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            this.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            this.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },
        closeReport() {
            this.selected = []
            this.reportView = false
            this.$router.push({
                name: 'SuperviseIssue',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        getHour(date) {
            var localLocale = moment(date)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('HH:mm')
        },
        spanishDateFormat(valDate) {
            var localLocale = moment(valDate)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('ddd DD MMM Y ')
        },

        load() {
            var self = this
            self.$overlay.show()

            if (this.$route.params.id) {
                var filterStates = self.$store.getters['issues/getFilterStates']()
                if (Object.keys(filterStates).length > 0) {
                    self.$store.commit('issues/canLoadList', false)
                    // self.$store.dispatch('issues/loadFilters', {}).then(function () {
                    //     self.goToReport({ id: self.$route.params.id })
                    //     self.$overlay.hide()
                    // })
                    self.goToReport({ id: self.$route.params.id })
                    self.$overlay.hide()
                } else {
                    self.$store.dispatch('issues/loadFilters', {}).then(function () {
                        self.$store.commit('issues/canLoadList', false)
                        // self.$store.dispatch('issues/loadFilters', {}).then(function () {
                        //     self.goToReport({ id: self.$route.params.id })
                        //     self.$overlay.hide()
                        // })
                        self.goToReport({ id: self.$route.params.id })
                        self.$overlay.hide()
                    })
                }
            } else {
                self.$store.commit('issues/canLoadList', true)
                this.reportView = false
                this.selected = []
                var filterLocations = self.$store.getters['filterLocations/getFilterLocation']()
                var filterTemplates = self.$store.getters['issues/getFilterTemplate']()
                var filterStates = self.$store.getters['issues/getFilterStates']()
                if (!this.$route.params.noChangePageDatatable) {
                    if (Object.keys(filterLocations).length > 0 && Object.keys(filterTemplates).length > 0 && Object.keys(filterStates).length > 0) {
                        self.$store.dispatch('issues/loadFilters', {}).then(function () {
                            self.applyFilter = true
                            self.loadViews()
                            self.$overlay.hide()
                        })
                    } else {
                        self.$store.dispatch('issues/loadFilters', {}).then(function () {
                            // self.applyFilter = true

                            self.loadViews()

                            self.$overlay.hide()
                        })
                    }
                } else {
                    if (this.items.length <= 0) {
                        self.$store.dispatch('issues/loadFilters', {}).then(function () {
                            self.loadViews()
                            self.$overlay.hide()
                        })
                    }
                    self.$overlay.hide()
                }
            }

            this.$route.params.noChangePageDatatable = false
        },
        getState(id) {
            var status = this.$store.getters['issues/getFilterStates'](id)
            var result = typeof status !== 'undefined' && status ? status : false
            if (id === 0) {
                result = ''
            }
            return result
        },
        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            return employee
        },
        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm')
                ? moment(value).format('DD/MM/YYYY HH:mm')
                : '-'
        },
        sortColumnCreatedBy(a, b) {
            var employeeA = a
            var employeeB = b
            if (!a == 0) {
                var getemployeeA = this.$store.getters['issues/getFilterStates'](a)
                employeeA = getemployeeA.name
            }
            if (!b == 0) {
                var getemployeeB = this.$store.getters['issues/getFilterStates'](b)
                employeeB = getemployeeB.name
            }

            if (employeeA < employeeB) {
                return -1
            }
            return 1
        },
        sortColumnDate(a, b) {
            if (a < b) {
                return -1
            }
            return 1
        },
        changePage() {
            this.top(true)
            this.changePagination = true
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('SuperviseIncidentsSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },

        // Filters VIEWS METHODS
        loadViews() {
            this.$store.dispatch('filtersViews/loadSuperviseIncidentsViews')
        },

        async handleDisplayView(view) {
            this.$overlay.show()

            const viewsAreUpdated = await this.$store.dispatch('filtersViews/loadSuperviseIncidentsViews')

            if (viewsAreUpdated) {
                const selectedViewCompleteData = await this.$store.getters['filtersViews/getSuperviseIncidentsViewById'](view.id)

                this.selectedView = selectedViewCompleteData

                this.$store.commit('filtersViews/setSuperviseIncidentsSelectedView', this.selectedView)

                this.$overlay.close()
            }
        },

        handleCreateView() {
            this.configViewIsEdit = false
            this.displayViewConfigForm = true
        },

        handleEditView() {
            this.configViewIsEdit = true
            this.displayViewConfigForm = true
        },

        closeViewConfigForm() {
            this.displayViewConfigForm = false
            this.configViewIsEdit = false
        },

        setDefaultViewAsSelectedView() {
            this.selectedView = structuredClone(this.defaultView)

            this.$store.commit('filtersViews/setSuperviseIncidentsSelectedView', this.selectedView)
        },
        downloadPDF() {
            const self = this

            let reportsQuantity
            if (self.selected.length > 0) {
                reportsQuantity = self.selected.length
            } else {
                reportsQuantity = parseInt(self.numItems)
            }

            const maxQuantAllowed = 10000

            // modal to inform that quantity is not allowed and suggest applying more filters
            if (reportsQuantity > maxQuantAllowed) {
                self.$popup.close()

                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_avoid.title'),
                    message: this.$t('supervise.reports.export.popup_avoid.message', { maxQuantAllowed: `<b>${maxQuantAllowed}</b>` }),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else if (reportsQuantity < 1 && reportsQuantity < maxQuantAllowed) {
                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else {
                // PDF display options modal
                self.$popup.PDFDisplayOptions({
                    callSave: this.handleExportReport, // calls de store
                    callCancel: () => {
                        self.moreOptions = false
                    },
                    config: {
                        quantity: reportsQuantity,
                        tool: 'issue'
                    }
                })
            }
        },
        getReportsIdsList(listOfReportsObjs) {
            if (!listOfReportsObjs || listOfReportsObjs.length === 0) {
                return null
            }

            const ids = listOfReportsObjs.map((report) => {
                if (report.hasOwnProperty('id')) {
                    return report.id
                }
            })

            return ids
        },
        handleExportReport() {
            const self = this

            // let reportsQuantity
            if (self.selected.length > 0) {
                const idsList = self.getReportsIdsList(self.selected)
                self.$store.commit('reports/setReportsToExportIds', idsList)
                // reportsQuantity = parseInt(idsList.length)
            } else {
                self.$store.commit('reports/setReportsToExportIds', null)
                // reportsQuantity = parseInt(self.numItems)
            }

            self.$popup.close()

            // display loader or loader popup
            // if (reportsQuantity >= 10) {
            //     self.$popup.exportLoading()
            // } else self.$overlay.loading()

            // display loader:
            self.$overlay.loading()

            self.$store.dispatch('reports/exportReports', { type: TYPE.ISSUE }).then((response) => {
                if (!response.status) {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // if is imposible to export, ask user to change de filters:
                    if (response.error.code == -2) {
                        self.$popup.messageWithButton({
                            title: this.$t('supervise.reports.export.popup_avoid.title'),
                            message: this.$t('supervise.reports.export.popup_avoid.message2'),
                            customClass: 'error',
                            callCancel: () => {
                                // close "more options" dropdown menu
                                self.moreOptions = false
                                // close popup
                                self.$popup.close()
                            }
                        })
                    } else {
                        // if error code is not -2, show the modal that says that pdfs will be sent by email:
                        self.showExportByEmailPopup()

                        // close "more options" dropdown menu
                        self.moreOptions = false
                    }

                    return
                } else {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // open PDFs in a new tab
                    window.open(response.url, '_blank')

                    // close "more options" dropdown menu
                    self.moreOptions = false
                }
            })
        },
        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email

            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },
        setInitialFiltersSidebarIsHiddenValue() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseIncidentsSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        },
        closeSidebarOnMobile() {
            if (this.isMobile) {
                this.filtersSidebarIsHidden = true
            }
        }
    },
    watch: {
        $route: 'load',
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
                this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
                this.filterKey++
            },
            deep: true
        },
        'pagination.itemsPerPage': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterIssues.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        'pagination.page': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterIssues.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },

        defaultView(defaultView) {
            if (!this.selectedView && this.items.length === 0 && defaultView) {
                this.setDefaultViewAsSelectedView()
            }
        },

        isSmallScreen(newVal, oldVal) {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseIncidentsSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        }
    },
    created() {
        this.load()

        this.selectedView = this.$store.getters['filtersViews/getSuperviseIncidentsSelectedView']

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if (this.$mq == 'portrait') {
            // By default, hide filters in portrait
            localStorage.setItem('SuperviseIncidentsSidebarIsHiddenValue', JSON.stringify(true))
        }
        this.setInitialFiltersSidebarIsHiddenValue()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.supervise-issue {
    .more-options {
        position: absolute;
        top: 42px;
        right: 4px;
        padding: 3px 3px 3px 3px;
        z-index: 9;
        background-color: $color-neutral-200;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        @media (min-width: 768px) {
            top: 54px;
            right: 16px;
        }

        button {
            display: block;
            // min-width: 153px;
            margin: 0px;
            margin-bottom: 4px !important;
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
    .supervise-container {
        display: flex;
        align-items: stretch;
        gap: 0.5em;

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;

                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    .estate {
                        @include background($color: $color-neutral-200);
                        margin: 6px 0;
                        min-height: 2rem;
                        height: auto;
                        width: auto;
                        text-align: center;
                        border-radius: 5px;
                        padding: 6px 9px;
                        font-family: $text-bold;
                    }
                }

                .id span {
                    font-family: $text-bold !important;
                }

                .employee-img {
                    @include background($size: cover);
                    vertical-align: middle;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                // .employee-name {
                //     @include text-ellipsis();
                //     display: inline-block;
                //     vertical-align: middle;
                //     width: calc(100% - 24px);
                //     padding-left: 6px;
                //     color: $color-black;
                // }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 3px;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
