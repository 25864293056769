<template>
    <div class="search-bar">
        <img
            src="img/icons/search-button-icon-black.svg"
            @click="
                () => {
                    $emit('magnifying-glass')
                }
            "
            alt="search icon"
            class="search-icon"
        />
        <input
            v-model="inputValue"
            type="text"
            @input="
                (event) => {
                    $emit('searchInputChange', event.target.value)
                }
            "
            v-on:keyup.enter="
                (event) => {
                    $emit('searchEnterChange', event.target.value)
                }"
            :placeholder="$t('nvgt.report.search_placeholder')"
        />

        <button v-if="inputValue.length > 0" @click="clearInput" class="clear-input" :aria-label="$t('button.clear-search')">
            <img src="img/icons/close_button_icon.svg" />
        </button>

        <div v-if="showNoResultsMsg" class="no-results-msg">
            <p>{{ $t('nvgt.report.no_results') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showNoResultsMsg: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputValue: ''
        }
    },
    methods: {
        clearInput() {
            this.$emit('searchInputChange', '')
            this.$emit('searchEnterChange', '')
            this.inputValue = ''
        }
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.search-bar {
    position: relative;
    width: 100%;
    margin: 0;
    background-color: white;
    border-radius: 4px;
    height: 30px;

    .search-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 8px;
        left: 8px;
    }

    input {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0 4px 0 29px;
        // padding: 0 40px 0 29px;
        height: 100%;
    }

    input::placeholder {
        font-size: 14px;
    }

    .no-results-msg {
        height: 16px;
        position: relative;

        p {
            position: absolute;
            top: 4px;
            font-size: 12px;
            color: $color-neutral-600;
        }
    }

    .clear-input {
        width: 24px;
        height: 24px;
        background-color: $color-neutral-300;
        position: absolute;
        top: 4px;
        right: 0;
        // padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 10px;
            height: 10px;
            max-width: 10px;
            max-height: 10px;
        }
    }
}
</style>
